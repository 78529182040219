<template>
  <v-container>
    <v-row>
      <v-col lg="8">
        <fee-payment-summary></fee-payment-summary>
      </v-col>
      <v-col lg="4">
        <FeeHeads :items="items" :room_id="room_id" :refresh="loadFees" :apiEndPoint="apiEndPoint"></FeeHeads>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FeeHeads from "@/components/fees/FeeHeads.vue";
import FeePaymentSummary from "@/components/fees/FeePaymentSummary.vue";

export default {
  props: ["room_id"],
  // provide() {
  //   return {
  //     refresh: this.loadFees,
  //   };
  // },
  components: {
    FeeHeads,
    FeePaymentSummary,
  },
  data() {
    return {
      items: [],
      summary: [],
    };
  },

  // created() {
  //   this.loadFees();
  // },
  // watch: {
  //   room_id: function () {
  //     this.loadFees();
  //   },
  // },
  computed: {
    apiEndPoint() {
      return `/rooms/${this.room_id}/fees/`
    }

  },
  methods: {
    loadFees() {
      this.$api
        .get(`/rooms/${this.room_id}/fees/`, {
          room_id: this.room_id,
        })
        .then((response) => {
          this.items = response.data.results;
        });
    },
  },
};
</script>

<style>

</style>

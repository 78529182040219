<template>
  <v-card>
    <v-card-title>Room Fee Summary</v-card-title>
    <data-table-ssr api-end-point="/payment/report/payment-summary?room=0d8474ed56344073ba2e3ba64a8f7daf"
      :headers="headers" :instantLoad="true">
    </data-table-ssr>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      payments: [],
      headers: [
        { text: "Room", value: "room" },
        { text: "January", value: "January" },
        { text: "February", value: "February" },
        { text: "March", value: "March" },
        { text: "April", value: "April" },
        { text: "May", value: "May" },
        { text: "June", value: "June" },
        { text: "July", value: "July" },
        { text: "August", value: "August" },
        { text: "September", value: "September" },
        { text: "October", value: "October" },
        { text: "November", value: "November" },
        { text: "December", value: "December" },
        {
          text: "Action",
          value: "action",
        },
      ],
    };
  },
  mounted() {
    this.loadFeeSummary();
  },
  methods: {
    loadFeeSummary() {
      this.$api
        .get(`/rooms/${this.$route.params.id}/payment-summary/`)
        .then((response) => {
          this.payments = response.data.results;
        });
    },
  },
  computed: {
    summary() {
      const summary = this.payments.map((r) => ({
        user: r.id,
        payments: r.room_user_fees.reduce((acc, fee) => {
          if (!acc[fee.month]) {
            acc[fee.month] = {
              paid: 0,
              total: 0,
            };
          }
          if (fee.room_fee_payment !== null) {
            acc[fee.month].paid += fee.payment_amount;
          }
          acc[fee.month].total += fee.amount;
          return acc;
        }, {}),
      }));
      return summary;
    },
  },
};
</script>

<style>

</style>
